import React, { useState, useEffect, useRef } from 'react';
import { Field } from 'formik';
import { startCase } from 'lodash';
import { useTimeout } from '@react-hook/timeout';
import { StyleSheet, css } from 'aphrodite';
import { useDispatch } from 'react-redux';

import {
  RadioButtonGroup,
  RadioButtonInputFormik,
  SmallRadioButtonInputFormik,
  SelectInputFormik,
  FormSection,
} from 'components/FormControls/FormControls';
import SectionHeader from 'components/SectionHeader/SectionHeader';
import UpsellModal from 'components/UpsellModal/UpsellModal';
import { PaymentActions } from 'redux/dux/payment';
import OtherAmountBox from './OtherAmountBox';
import {
  useTypedSelector,
  gtmEventSetGiftFrequency,
  gtmEventSetPreselectedGiftAmount,
} from 'shared/utils';
import { FREQUENCY, DefaultMailCodes } from 'constants/constants';
import { DonationTemplateSchema } from 'types/shared';
import { generateDonationMessage } from 'helpers/utils';
import { DonationTheme } from 'themes';

interface Props {
  values: any;
  errors: any;
  theme: DonationTheme;
  mailCodeNameParam?: string;
  resetForm: ({ a }) => {};
  setFieldValue: (
    field: string,
    newValue: any,
    shouldValidate?: boolean
  ) => void;
  showUpsell: boolean;
  setShowUpsell: (b: boolean) => void;
  donationLevelParam?: number;
  donationAmountParam?: string;
  otherAmountParam?: string;
  giftFrequencyParam?: string;
}

const DonateForm = ({
  values,
  errors,
  theme,
  mailCodeNameParam,
  resetForm,
  setFieldValue,
  showUpsell,
  setShowUpsell,
  donationLevelParam,
  donationAmountParam,
  giftFrequencyParam,
}: Props) => {
  const dispatch = useDispatch();
  const didMountAmountRef = useRef<boolean>(false);
  const didMountFrequencyRef = useRef<boolean>(false);
  const hasDonationLevelParam = Boolean(donationAmountParam);
  const hasDonationFrequencyParam = Boolean(giftFrequencyParam);
  const [isBoxOpen, setIsBoxOpen] = useState<boolean>(false);
  const [hasSelectedMonthly, setHasSelectedMonthly] = useState<boolean>(false);

  const donationTemplateSchema: DonationTemplateSchema = useTypedSelector(
    state => state.donationTemplateSchema.donationTemplateSchema
  );

  const {
    donationTemplate: {
      isUk,
      defaultCurrency: { icon },
      giftArray,
      donationAmountOneTime,
      donationAmountMonthly,
      donationAmountYearly,
      defaultDonationLevel,
      isMonthlyUpsellActive,
      upsellModalDelaySeconds,
      primaryMailCode,
      donationFormMainContent,
    },
    mailCodeGroup,
    donationFrequencyNames,
  } = donationTemplateSchema;

  const [timedOut, startTimeout] = useTimeout(upsellModalDelaySeconds * 1000);

  const [displayedAmounts, setDisplayedAmounts] = useState(() => {
    if (donationAmountOneTime.donationAmounts.length > 0) {
      return donationAmountOneTime;
    } else if (donationAmountMonthly.donationAmounts.length > 0) {
      return donationAmountMonthly;
    } else {
      return donationAmountYearly;
    }
  });

  useEffect(() => {
    if (values.giftFrequency === FREQUENCY.ONE_TIME) {
      setDisplayedAmounts(donationAmountOneTime);
    }

    if (values.giftFrequency === FREQUENCY.MONTHLY) {
      setDisplayedAmounts(donationAmountMonthly);
    }

    if (values.giftFrequency === FREQUENCY.ANNUAL) {
      setDisplayedAmounts(donationAmountYearly);
    }
  }, [
    values.giftFrequency,
    donationAmountOneTime,
    donationAmountMonthly,
    donationAmountYearly,
  ]);

  // Sets the default donation amount that is defined either as a URL query
  // parameter or in GraphCMS. The query param takes precedence. If no default
  // is set, use an empty string for the donationAmount
  useEffect(() => {
    let donationLevel = '';

    if (donationAmountParam === 'OtherAmount') {
      donationLevel = 'OtherAmount';
      setIsBoxOpen(true);
    } else if (defaultDonationLevel) {
      donationLevel = displayedAmounts.donationAmounts[
        defaultDonationLevel - 1
      ]?.amount.toString();
    }

    setFieldValue(
      'donationAmount',
      donationLevelParam
        ? displayedAmounts.donationAmounts[
            donationLevelParam - 1
          ].amount.toString()
        : donationLevel
    );
  }, [
    defaultDonationLevel,
    donationLevelParam,
    setFieldValue,
    displayedAmounts,
    donationAmountParam,
  ]);

  // Sets the gift frequency amount if one is set on query parameters
  useEffect(() => {
    if (giftFrequencyParam !== undefined) {
      setFieldValue('giftFrequency', giftFrequencyParam);
    }
  }, [giftFrequencyParam, setFieldValue]);

  // Generate the upsell amount that is related to the currently selected donation amount
  const mappedUpsellAmount = donationAmountOneTime.donationAmounts.find(
    donation => donation.amount === Number(values.donationAmount)
  )?.upsellAmount;

  // The following two effects deal with initializing the modal timeout, and
  // firing the modal respectively
  useEffect(() => {
    if (
      values.giftFrequency === FREQUENCY.ONE_TIME &&
      mappedUpsellAmount &&
      isMonthlyUpsellActive &&
      !isUk
    ) {
      startTimeout();
    }
  }, [
    values.donationAmount,
    values.giftFrequency,
    mappedUpsellAmount,
    isMonthlyUpsellActive,
    startTimeout,
    isUk,
  ]);

  useEffect(() => {
    const shouldShowUpsell =
      timedOut &&
      values.donationAmount &&
      values.donationAmount !== 'OtherAmount' &&
      values.giftFrequency === FREQUENCY.ONE_TIME &&
      !hasSelectedMonthly;

    setShowUpsell(shouldShowUpsell);
    // We don't want to include `values.donationAmount` in the dependency array
    // because it will cause the upsell modal to trigger if the user declines
    // the upsell then selects another one-time amount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timedOut, setShowUpsell, values.giftFrequency, hasSelectedMonthly]);

  // Deals with pushing donation frequency values into Google Tag Manager
  useEffect(() => {
    if (didMountFrequencyRef.current) {
      gtmEventSetGiftFrequency(values.giftFrequency);
    } else if (!hasDonationFrequencyParam) {
      gtmEventSetGiftFrequency(values.giftFrequency);

      didMountFrequencyRef.current = true;
    } else {
      didMountFrequencyRef.current = true;
    }
  }, [values.giftFrequency, hasDonationFrequencyParam]);

  // Deals with pushing selected amount values into Google Tag Manager
  useEffect(() => {
    if (didMountAmountRef.current) {
      gtmEventSetPreselectedGiftAmount(values.donationAmount);
    } else if (!hasDonationLevelParam) {
      gtmEventSetPreselectedGiftAmount(values.donationAmount);

      didMountAmountRef.current = true;
    } else {
      didMountAmountRef.current = true;
    }
  }, [values.donationAmount, hasDonationLevelParam]);

  // Clears the otherAmount formik value if the user switches to a pre-defined donation amount.
  // This prevents sending an empty other amount in the GTM donationCompleted event.
  useEffect(() => {
    if (values.donationAmount !== 'OtherAmount') {
      values.otherAmount = '';
      setIsBoxOpen(false);
    }
  }, [values.donationAmount, values.otherAmount]);

  // Determines which message to display based on which giftFrequency is currently selected
  // This is passed to the generateDonationMessage function to build up the message string
  let displayedMessages =
    values.giftFrequency === FREQUENCY.ONE_TIME
      ? donationAmountOneTime.donationAmounts
      : values.giftFrequency === FREQUENCY.MONTHLY
      ? donationAmountMonthly.donationAmounts
      : values.giftFrequency === FREQUENCY.ANNUAL
      ? donationAmountYearly.donationAmounts
      : [];

  // Generate options for mail code select from CMS values
  // These options are for the "What Prompted Your Donation Today" select
  const mailCodesOptions = mailCodeGroup.mailCodes.map(code => {
    return { code_name: code.mailCodeName, code_label: code.mailCodeLabel };
  });

  const monthlyAmounts = donationAmountMonthly.donationAmounts.map(
    donation => donation.amount
  );

  // Calculate how many gift frequency buttons will be shown on the form
  let numOfFrequencyButtons = 0;
  giftArray.oneTimeDonationAmounts.length > 0 && numOfFrequencyButtons++;
  giftArray.monthlyDonationAmounts.length > 0 && numOfFrequencyButtons++;
  giftArray.yearlyDonationAmounts.length > 0 && numOfFrequencyButtons++;

  const isMonthlyFirst =
    giftArray.oneTimeDonationAmounts.length === 0 &&
    giftArray.monthlyDonationAmounts.length > 0
      ? true
      : false;

  const isDefaultMailCode =
    primaryMailCode.mailCodeName === DefaultMailCodes.UsDefault ||
    primaryMailCode.mailCodeName === DefaultMailCodes.UkDefault;

  return (
    <div className="container module">
      <SectionHeader
        name={donationFormMainContent.giftFrequencyHeader}
        sectionHeaderStyle={theme.sectionHeader}
      />
      <FormSection>
        <RadioButtonGroup name="giftFrequency">
          {!!donationAmountOneTime &&
            donationAmountOneTime.donationAmounts.length > 0 && (
              <Field
                onClick={() => {
                  dispatch(PaymentActions.setUpsell(null));
                  resetForm({ ...values, donationAmount: '' });
                }}
                name="giftFrequency"
                id={FREQUENCY.ONE_TIME}
                smallButtonStyle={theme.smallButtonGroup.firstButton}
                label={startCase(donationFrequencyNames[FREQUENCY.ONE_TIME])}
                component={SmallRadioButtonInputFormik}
                extraStyle={
                  numOfFrequencyButtons === 1 ? styles.fullButton : ''
                }
              />
            )}
          {isUk && donationAmountMonthly.donationAmounts.length > 0 && (
            <div className="radio-button-input">
              <a
                target="_blank"
                rel="noopener noreferrer"
                className={`radio-button-input radio-button-label ${css(
                  styles.regularButton
                )}`}
                href="https://secure.edirectdebit.co.uk/Smile-Train/Donate"
                id="donate-form-uk-recurring"
              >
                {donationFrequencyNames.uk[FREQUENCY.MONTHLY]}
              </a>
            </div>
          )}
          {!isUk &&
            !!donationAmountMonthly &&
            donationAmountMonthly.donationAmounts.length > 0 && (
              <Field
                onClick={() => {
                  resetForm({ ...values, donationAmount: '' });
                  setHasSelectedMonthly(true);
                }}
                name="giftFrequency"
                id={FREQUENCY.MONTHLY}
                label={donationFrequencyNames[FREQUENCY.MONTHLY]}
                component={SmallRadioButtonInputFormik}
                smallButtonStyle={theme.smallButtonGroup.secondButton}
                extraStyle={
                  numOfFrequencyButtons === 1
                    ? styles.fullButton
                    : numOfFrequencyButtons === 2
                    ? isMonthlyFirst
                      ? styles.leftButton
                      : styles.rightButton
                    : ''
                }
              />
            )}
          {!isUk &&
            !!donationAmountYearly &&
            donationAmountYearly.donationAmounts.length > 0 && (
              <Field
                onClick={() => {
                  dispatch(PaymentActions.setUpsell(null));
                  resetForm({ ...values, donationAmount: '' });
                }}
                name="giftFrequency"
                id={FREQUENCY.ANNUAL}
                smallButtonStyle={theme.smallButtonGroup.thirdButton}
                label={donationFrequencyNames[FREQUENCY.ANNUAL]}
                component={SmallRadioButtonInputFormik}
                extraStyle={
                  numOfFrequencyButtons === 1
                    ? styles.fullButton
                    : numOfFrequencyButtons === 2
                    ? styles.rightButton
                    : ''
                }
              />
            )}
        </RadioButtonGroup>
        <div>
          {errors['giftFrequency'] && (
            <div className="input-feedback">{errors['giftFrequency']}</div>
          )}
        </div>
        {!isUk && (
          <UpsellModal
            showUpsell={showUpsell}
            setShowUpsell={setShowUpsell}
            originalAmount={values.donationAmount}
            upsellAmount={mappedUpsellAmount}
            setFieldValue={setFieldValue}
            monthlyAmounts={monthlyAmounts}
            setHasSelectedMonthly={setHasSelectedMonthly}
          />
        )}
      </FormSection>
      <FormSection>
        <SectionHeader
          name={donationFormMainContent.giftAmountHeader}
          sectionHeaderStyle={theme.sectionHeader}
        />
        <RadioButtonGroup
          name="donationAmount"
          errors={errors}
          message={generateDonationMessage(values, displayedMessages)}
        >
          {values.giftFrequency &&
            typeof displayedAmounts !== 'undefined' &&
            displayedAmounts.donationAmounts.map(o => {
              if (o.amount !== null) {
                return (
                  <Field
                    key={o.amount}
                    name="donationAmount"
                    id={o.amount.toString()}
                    label={`${icon}${o.amount}`}
                    smallButtonStyle={theme.smallAmountButton}
                    component={RadioButtonInputFormik}
                    onClick={() => setIsBoxOpen(false)}
                  />
                );
              }
              return null;
            })}

          {values.giftFrequency && displayedAmounts.otherAmount && (
            <Field
              name="donationAmount"
              id="OtherAmount"
              label={
                values.donationAmount === 'OtherAmount'
                  ? `${
                      values.otherAmount === undefined
                        ? donationFormMainContent.otherAmountDonationName
                        : icon + values.otherAmount
                    }`
                  : donationFormMainContent.otherAmountDonationName
              }
              smallButtonStyle={theme.smallAmountButton}
              component={RadioButtonInputFormik}
              fill="true"
              onClick={() => setIsBoxOpen(true)}
            />
          )}
        </RadioButtonGroup>
        {isBoxOpen && (
          <OtherAmountBox
            currencyIcon={icon}
            errors={errors}
            donationAmount={values.donationAmount}
            otherAmount={values.otherAmount}
          />
        )}
      </FormSection>
      {// Does the template allow for whatPromptedYourDonationToday dropdown?
      donationTemplateSchema.donationTemplate.whatPromptedYourDonationToday &&
        // Are there no mail codes set on urlParams or the STAdSrc cookie?
        mailCodeNameParam === undefined &&
        // Did we fall through to the default mail codes case?
        isDefaultMailCode && (
          <FormSection>
            <SectionHeader
              name={donationFormMainContent.mailCodeFormHeader}
              sectionHeaderStyle={theme.sectionHeader}
            />
            <Field
              name="mailCodeName"
              options={mailCodesOptions}
              component={SelectInputFormik}
            />
          </FormSection>
        )}
    </div>
  );
};

export default DonateForm;

const styles = StyleSheet.create({
  regularButton: {
    marginLeft: 0,
    borderLeftStyle: 'none',
    color: '#777',
    borderRadius: '0 8px 8px 0',
  },
  leftButton: {
    borderRadius: '8px 0 0 8px',
    borderRightStyle: 'none',
  },
  rightButton: {
    borderRadius: '0 8px 8px 0',
    borderRightStyle: 'solid',
  },
  fullButton: {
    borderRadius: '8px',
  },
});
