"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.replaceTemplateStrings = exports.returnStateOrProvinceAbbreviation = void 0;
const constants_1 = require("../constants/constants");
/**
 * Converts US state or CA province ids (as enumerated in USStateList/CAProvincesList arrays) into their respective abbreviations
 * @param billingState state/province id value
 * @param billingCountry country name
 * @returns state/province abbreviation
 */
const returnStateOrProvinceAbbreviation = (billingState, billingCountry) => {
    let fullStateObject;
    let stateName;
    let stateAbbreviation = '';
    if (billingCountry === 'Canada') {
        fullStateObject = constants_1.CAProvincesList.find(province => province.province_id.toString() === billingState);
        if (typeof fullStateObject !== 'undefined') {
            stateName = fullStateObject.province;
            stateAbbreviation = stateName.slice(0, stateName.indexOf('-')).trim();
        }
    }
    else if (billingCountry === 'United States') {
        fullStateObject = constants_1.USStateList.find(state => state.state_id.toString() === billingState);
        if (typeof fullStateObject !== 'undefined') {
            stateName = fullStateObject.state;
            stateAbbreviation = stateName.slice(0, stateName.indexOf('-')).trim();
        }
    }
    return stateAbbreviation;
};
exports.returnStateOrProvinceAbbreviation = returnStateOrProvinceAbbreviation;
const replaceTemplateStrings = (contentString, templateObject) => {
    let newTemplateString = contentString;
    for (const [key, value] of Object.entries(templateObject)) {
        const templateRegex = new RegExp('{' + key + '}');
        newTemplateString = newTemplateString.replace(templateRegex, value);
    }
    return newTemplateString;
};
exports.replaceTemplateStrings = replaceTemplateStrings;
